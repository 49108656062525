@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Lato";
  src: url("fonts/Lato/lato-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "Varela Round";
  src: url("fonts/Varela_Round/varelaround-regular-webfont.eot");
  src: url("fonts/Varela_Round/varelaround-regular-webfont.woff") format("woff"),
    url("fonts/Varela_Round/varelaround-regular-webfont.svg") format("svg");
}

@font-face {
  font-family: "Spork";
  src: url("fonts/Spork/Spork.eot?") format("eot"), url("fonts/Spork/Spork.woff") format("woff"),
    url("fonts/Spork/Spork.ttf") format("truetype"), url("fonts/Spork/Spork.svg#Spork") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SporkBold";
  src: url("fonts/SporkBold/SporkBold.eot?") format("eot"), url("fonts/SporkBold/SporkBold.woff") format("woff"),
    url("fonts/SporkBold/SporkBold.ttf") format("truetype"),
    url("fonts/SporkBold/SporkBold.svg#SporkBold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Urbanist";
  src: url("fonts/Urbanist/otf/Urbanist-Regular.otf") format("otf"), url("fonts/Urbanist/webfonts/Urbanist-Regular.woff2") format("woff2"),
    url("fonts/Urbanist/ttf/Urbanist-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "UrbanistBlack";
  src: url("fonts/Urbanist/otf/Urbanist-Black.otf") format("otf"), url("fonts/Urbanist/webfonts/Urbanist-Black.woff2") format("woff2"),
    url("fonts/Urbanist/ttf/Urbanist-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, 'SporkBold', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Spork', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: -apple-system, BlinkMacSystemFont, 'Urbanist', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.buttonlogo {
  filter: invert(52%) sepia(98%) saturate(1000%) hue-rotate(4deg) brightness(100%) contrast(98%);
}